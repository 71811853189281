// extracted by mini-css-extract-plugin
export var blockDescription = "payload-module--block-description--46c96";
export var blocksContainer = "payload-module--blocks-container--aebb6";
export var blocksInRowsWithOneActiveInnerWrapper = "payload-module--blocks-in-rows-with-one-active-inner-wrapper--90876";
export var blocksInRowsWithOneActiveInnerWrapperTwo = "payload-module--blocks-in-rows-with-one-active-inner-wrapper-two--16d4b";
export var blocksInRowsWithOneActiveSection = "payload-module--blocks-in-rows-with-one-active-section--4f5d0";
export var blocksInRowsWithOneActiveSectionTwo = "payload-module--blocks-in-rows-with-one-active-section-two--d66d3";
export var blocksSectionInnerWrapper = "payload-module--blocks-section-inner-wrapper--450ae";
export var blocksSectionSubtitle = "payload-module--blocks-section-subtitle--8a98b";
export var blocksSectionTitlesContainer = "payload-module--blocks-section-titles-container--5e65d";
export var coloredBlocksContainer = "payload-module--colored-blocks-container--9d1a9";
export var coloredBlocksInnerWrapper = "payload-module--colored-blocks-inner-wrapper--7cce3";
export var coloredBlocksRightPart = "payload-module--colored-blocks-right-part--07a4b";
export var commonOuterWrapper = "payload-module--common-outer-wrapper--e8108";
export var companyInNumbersSection = "payload-module--company-in-numbers-section--72dd2";
export var companyInNumbersTitlesContainer = "payload-module--company-in-numbers-titles-container--6cc76";
export var contactButtonTextSection = "payload-module--contact-button-text-section--ec72c";
export var contactButtonTextSectionContainer = "payload-module--contact-button-text-section-container--2f8dc";
export var contactButtonTextSectionInnerWrapper = "payload-module--contact-button-text-section-inner-wrapper--51eee";
export var headerBorder = "payload-module--header-border--a33cd";
export var headerInnerWrapper = "payload-module--header-inner-wrapper--36d96";
export var headerTitle = "payload-module--header-title--1a64f";
export var headerTitleBorderWidth = "payload-module--header-title-border-width--a4180";
export var innerWrapperTestimonials = "payload-module--inner-wrapper-testimonials--6d495";
export var opacitySliderInnerWrapper = "payload-module--opacity-slider-inner-wrapper--a8e90";
export var opacitySliderTitlesContainer = "payload-module--opacity-slider-titles-container--ff61d";
export var sectionUnderHeaderButton = "payload-module--section-under-header-button--e3e2e";
export var sectionUnderHeaderContentWrapper = "payload-module--section-under-header-content-wrapper--c9a98";
export var sectionUnderHeaderInnerWrapper = "payload-module--section-under-header-inner-wrapper--8f649";
export var sectionUnderHeaderSliderInnerWrapper = "payload-module--section-under-header-slider-inner-wrapper--e9ae5";
export var sectionUnderHeaderText = "payload-module--section-under-header-text--eeff7";
export var sectionUnderHeaderToggleVisibilityButton = "payload-module--section-under-header-toggle-visibility-button--ef8d5";
export var singleBlockContainer = "payload-module--single-block-container--10a14";
export var sliderWithClickableBlocksInnerWrapper = "payload-module--slider-with-clickable-blocks-inner-wrapper--df079";
export var sliderWithClickableBlocksSection = "payload-module--slider-with-clickable-blocks-section--60ade";
export var sliderWithClickableBlocksTitlesContainer = "payload-module--slider-with-clickable-blocks-titles-container--ad1f7";
export var tabsInColumnWithOneActiveInnerWrapper = "payload-module--tabs-in-column-with-one-active-inner-wrapper--b58fd";
export var techStackContainerWithTwoColumns = "payload-module--tech-stack-container-with-two-columns--4397a";
export var techStackIconsForCategory = "payload-module--tech-stack-icons-for-category--97f89";
export var techStackInnerWrapper = "payload-module--tech-stack-inner-wrapper--fab05";
export var techStackRectangle = "payload-module--tech-stack-rectangle--81201";
export var techStackSingleCategory = "payload-module--tech-stack-single-category--ee1db";
export var techStackSingleColumn = "payload-module--tech-stack-single-column--b949b";
export var techStackSubtitle = "payload-module--tech-stack-subtitle--f20ba";
export var techStackTitlesContainer = "payload-module--tech-stack-titles-container--0d101";
export var titlesContainer = "payload-module--titles-container--89ec2";